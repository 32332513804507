// src/pages/index.js

import React from 'react'
import Layout from '../components/layout';
import * as styles from './e-books.module.css';


const IndexPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">E-Books</h1>
          <p>E-Books on weight training, goal setting, psychology, and other practical philosophy topics.</p>
        </div>
      </section>
      <section className="content">
        <div className="container">
          <article>
            <h2>No e-books yet. Check back later.</h2>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
